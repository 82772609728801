import React from "react"
import App from "./App.tsx"
import i18n from "./locales/i18n.ts"
import "antd/dist/reset.css"
import "./assets/fonts/helvetica-now-text/index.css"
import "./index.scss"

import { App as AppAntd, ConfigProvider } from "antd"
import { I18nextProvider } from "react-i18next"
import { antdTheme } from "./others/AntdTheme.ts"
import { createRoot } from "react-dom/client"
import { AntdLangContext } from "./others/AntdLangContext.tsx"
import { AppCtxProvider } from "@contexts/providers/AppCtxProvider.tsx"
import { MocksConstants } from "./mocks/mocks.constants.ts"

async function enableMocking() {
	if (process.env.NODE_ENV !== "development" || !MocksConstants.USE_MOCKS)
		return

	const { worker } = await import("./mocks/browser")
	return worker.start({
		onUnhandledRequest: "bypass",
	})
}

enableMocking().then(() => {
	createRoot(document.getElementById("root")!).render(
		<React.StrictMode>
			<ConfigProvider theme={antdTheme}>
				<AppAntd>
					<AntdLangContext>
						<I18nextProvider i18n={i18n}>
							<AppCtxProvider>
								<App />
							</AppCtxProvider>
						</I18nextProvider>
					</AntdLangContext>
				</AppAntd>
			</ConfigProvider>
		</React.StrictMode>
	)
})
