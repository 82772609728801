import * as React from "react"
import * as Antd from "antd"
import styles from "./MembershipJobsForm.module.scss"

import { useTranslation } from "react-i18next"
import { AppFunctions } from "@others/AppFunctions.ts"
import { useNavigate } from "react-router-dom"
import { MemberShipLayoutConstants } from "@components/layout/MembershipLayout/MembershipLayout.constants.ts"
import { AppRoutes } from "@constants/AppRoutes.ts"
import { useListKindOfJobs } from "@hooks/useListKindOfJobs.tsx"
import { useListJobRoles } from "@hooks/useListJobRoles.tsx"

import DsButton from "@components/general/DsButton/DsButton.tsx"
import DvSelect from "@components/dataEntry/DvSelect/DvSelect.tsx"
import useAuth from "@contexts/hooks/useAuth.tsx"
import DvInput from "@components/dataEntry/DvInput/DvInput.tsx"

type FieldType = {
	job: string
	kindOfJob: string
	role: string
	workEmail: string
}

interface IProps {
	className?: string
}

const MembershipJobsForm: React.FC<IProps> = (props) => {
	const navigate = useNavigate()

	const { loggedUser } = useAuth()
	const { t } = useTranslation()
	const { kindOfJobs } = useListKindOfJobs()
	const { jobRoles } = useListJobRoles()

	const initialValues: FieldType = {
		job:
			AppFunctions.getMembershipFormDataKey("job") || loggedUser?.company,
		kindOfJob: AppFunctions.getMembershipFormDataKey("kindOfJob"),
		role: AppFunctions.getMembershipFormDataKey("role"),
		workEmail: AppFunctions.getMembershipFormDataKey("workEmail"),
	}

	const onFinish = ({ job, kindOfJob, role, workEmail }: FieldType) => {
		AppFunctions.setMembershipFormDataKey("job", job)
		AppFunctions.setMembershipFormDataKey("kindOfJob", kindOfJob)
		AppFunctions.setMembershipFormDataKey("role", role)
		AppFunctions.setMembershipFormDataKey("workEmail", workEmail)

		navigate(AppRoutes.membershipFormStep3_presentation, { replace: true })
	}

	return (
		<Antd.Form<FieldType>
			className={`${styles.membership_personal_details_form} ${
				props.className || ""
			}`}
			name="basic"
			labelCol={{ span: 8 }}
			wrapperCol={{ span: 16 }}
			initialValues={initialValues}
			onFinish={onFinish}
			autoComplete="off"
			layout="vertical"
		>
			<Antd.Form.Item<FieldType>
				className={styles.form_item}
				label={t("where_do_you_work")}
				name="job"
				wrapperCol={{ span: 24 }}
				validateTrigger="onSubmit"
				rules={[
					{
						required: true,
						message: t("please_enter_your_job"),
					},
				]}
			>
				<DvInput
					placeholder={t("insert_your_job")}
					onChange={(e) => {
						AppFunctions.setMembershipFormDataKey(
							"job",
							e.target.value
						)
					}}
				/>
			</Antd.Form.Item>

			<Antd.Form.Item<FieldType>
				className={styles.form_item}
				label={t("kind_of_job")}
				name="kindOfJob"
				wrapperCol={{ span: 24 }}
				validateTrigger="onSubmit"
				rules={[
					{
						required: true,
						message: t("please_enter_your_kind_of_job"),
					},
				]}
			>
				<DvSelect
					options={kindOfJobs}
					onSelect={(value) => {
						AppFunctions.setMembershipFormDataKey(
							"kindOfJob",
							value
						)
					}}
				/>
			</Antd.Form.Item>

			<Antd.Form.Item<FieldType>
				className={styles.form_item}
				label={t("whats_your_role")}
				name="role"
				wrapperCol={{ span: 24 }}
				validateTrigger="onSubmit"
				rules={[
					{
						required: true,
						message: t("please_enter_your_role"),
					},
				]}
			>
				<DvSelect
					options={jobRoles}
					onSelect={(value) => {
						AppFunctions.setMembershipFormDataKey("role", value)
					}}
				/>
			</Antd.Form.Item>

			<Antd.Form.Item<FieldType>
				className={styles.form_item}
				label={t("work_email")}
				name="workEmail"
				wrapperCol={{ span: 24 }}
				validateTrigger="onSubmit"
				rules={[
					{
						required: true,
						message: t("please_enter_your_work_email"),
					},
					{
						type: "email",
						message: t("your_email_is_not_valid"),
					},
				]}
			>
				<DvInput
					placeholder={t("insert_your_work_email")}
					onChange={(e) => {
						AppFunctions.setMembershipFormDataKey(
							"workEmail",
							e.target.value
						)
					}}
				/>
			</Antd.Form.Item>

			<DsButton
				id={MemberShipLayoutConstants.SUBMIT_FORM_BUTTON_ID}
				style={{ display: "none" }}
				htmlType="submit"
			/>
		</Antd.Form>
	)
}

export default React.memo(MembershipJobsForm)
