import * as React from "react"
import styles from "./UserSettings.module.scss"

import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { AppRoutes } from "@constants/AppRoutes.ts"

import UserInfo from "@pages/UserProfile/UserInfo/UserInfo.tsx"
import DsButton from "@components/general/DsButton/DsButton.tsx"
import MenuItem from "@pages/UserSettings/MenuItem/MenuItem.tsx"
import useAuth from "@contexts/hooks/useAuth.tsx"

interface IProps {
	className?: string
}

const UserSettings: React.FC<IProps> = (props) => {
	const navigate = useNavigate()

	const { t } = useTranslation()
	const { loggedUser } = useAuth()

	return (
		<div className={`${styles.user_settings} ${props.className || ""}`}>
			<div className={styles.content}>
				<UserInfo />

				<div className={styles.menu}>
					<MenuItem
						label="Datos personales"
						onClick={() => {
							navigate(AppRoutes.userPersonalDataForm)
						}}
					/>

					{!loggedUser?.hasMembershipInProgress &&
						!loggedUser?.hasAnApprovedMembership && (
							<MenuItem
								label="Membresía"
								icon={
									<DsButton
										className={styles.button}
										type="secondary"
										onClick={() =>
											navigate(AppRoutes.membershipPlans)
										}
									>
										{t("apply_to_a_membership")}
									</DsButton>
								}
							/>
						)}
				</div>
			</div>
		</div>
	)
}

export default React.memo(UserSettings)
