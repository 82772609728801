import * as React from "react"
import * as Antd from "antd"
import styles from "./MembershipPersonalDetailsForm.module.scss"

import { useTranslation } from "react-i18next"
import { AppFunctions } from "@others/AppFunctions.ts"
import { useNavigate } from "react-router-dom"
import { MemberShipLayoutConstants } from "@components/layout/MembershipLayout/MembershipLayout.constants.ts"
import { useListOfAllCountries } from "@hooks/useListOfAllCountries.tsx"
import { useListOfGenders } from "@hooks/useListOfGenders.tsx"
import { AppRoutes } from "@constants/AppRoutes.ts"
import { AppLocalStorageKeys } from "@constants/AppLocalStorageKeys.ts"

import DsButton from "@components/general/DsButton/DsButton.tsx"
import useAuthCtx from "@contexts/hooks/useAuth.tsx"
import DvPhotoUpload from "@components/dataEntry/DvPhotoUpload/DvPhotoUpload.tsx"
import DvSelect from "@components/dataEntry/DvSelect/DvSelect.tsx"
import DvCheckbox from "@components/dataEntry/DvCheckbox/DvCheckbox.tsx"
import DvInput from "@components/dataEntry/DvInput/DvInput.tsx"
import PhotoUploadFormItem from "@components/layout/PhotoUploadFormItem/PhotoUploadFormItem.tsx"
import IndexedDBService from "@services/IndexedDBService.ts"

type FieldType = {
	profilePhoto?: string
	phone: string
	nationality: string
	gender: string
	nationalId: string
	receivePromotionsByWhatsapp: boolean
}

interface IProps {
	className?: string
}

const MembershipPersonalDetailsForm: React.FC<IProps> = (props) => {
	const navigate = useNavigate()

	const { t } = useTranslation()
	const { loggedUser } = useAuthCtx()
	const { countries, loading } = useListOfAllCountries()
	const { genders } = useListOfGenders()

	const [profilePhoto, setProfilePhoto] = React.useState<string | undefined>()

	const initialValues: FieldType = {
		profilePhoto: profilePhoto,
		nationality: AppFunctions.getMembershipFormDataKey("nationality"),
		gender: AppFunctions.getMembershipFormDataKey("gender"),
		nationalId: AppFunctions.getMembershipFormDataKey("nationalId"),
		phone:
			AppFunctions.getMembershipFormDataKey("phone") || loggedUser?.phone,
		receivePromotionsByWhatsapp:
			AppFunctions.getMembershipFormDataKey(
				"receivePromotionsByWhatsapp"
			) ?? true,
	}

	const onFinish = ({
		receivePromotionsByWhatsapp,
		nationalId,
		phone,
		gender,
		nationality,
	}: FieldType) => {
		AppFunctions.setMembershipFormDataKey("nationalId", nationalId)
		AppFunctions.setMembershipFormDataKey("phone", phone)
		AppFunctions.setMembershipFormDataKey("gender", gender)
		AppFunctions.setMembershipFormDataKey("nationality", nationality)

		AppFunctions.setMembershipFormDataKey(
			"receivePromotionsByWhatsapp",
			receivePromotionsByWhatsapp
		)

		navigate(AppRoutes.membershipFormStep2_job, { replace: true })
	}

	React.useEffect(() => {
		IndexedDBService.getKey<FieldType>(
			AppLocalStorageKeys.APPLY_MEMBERSHIP_FORM_DATA
		).then((formData) => {
			if (formData?.profilePhoto) {
				setProfilePhoto(formData.profilePhoto)
			} else if (loggedUser?.profilePhoto) {
				setProfilePhoto(loggedUser?.profilePhoto)
			}
		})
	}, [loggedUser?.profilePhoto])

	return (
		<Antd.Form<FieldType>
			className={`${styles.membership_personal_details_form} ${
				props.className || ""
			}`}
			name="basic"
			labelCol={{ span: 8 }}
			wrapperCol={{ span: 16 }}
			initialValues={initialValues}
			onFinish={onFinish}
			autoComplete="off"
			layout="vertical"
		>
			<PhotoUploadFormItem>
				<DvPhotoUpload
					initialBase64Image={initialValues.profilePhoto}
					base64Image={profilePhoto}
					onSuccess={({ base64Image }) => {
						setProfilePhoto(base64Image)

						IndexedDBService.addKey(
							AppLocalStorageKeys.APPLY_MEMBERSHIP_FORM_DATA,
							{ profilePhoto: base64Image }
						)
					}}
				/>
			</PhotoUploadFormItem>

			<Antd.Form.Item<FieldType>
				className={styles.form_item}
				label={t("phone")}
				name="phone"
				wrapperCol={{ span: 24 }}
				validateTrigger="onSubmit"
				rules={[
					{
						required: true,
						message: t("please_enter_your_phone"),
					},
					{
						pattern: /^\+?[1-9]\d{1,14}$/, // E.164 format: +[country code][number]
						message: t("message_your_phone_is_not_valid"),
					},
				]}
			>
				<DvInput
					placeholder={t("insert_your_phone")}
					onChange={(e) => {
						AppFunctions.setMembershipFormDataKey(
							"phone",
							e.target.value
						)
					}}
				/>
			</Antd.Form.Item>

			<Antd.Form.Item<FieldType>
				className={styles.form_item}
				label={t("nationality")}
				name="nationality"
				wrapperCol={{ span: 24 }}
				validateTrigger="onSubmit"
				rules={[
					{
						required: true,
						message: t("please_select_your_nationality"),
					},
				]}
			>
				<DvSelect
					options={countries}
					loading={loading}
					placeholder={t("select_your_nationality")}
					onSelect={(value) => {
						AppFunctions.setMembershipFormDataKey(
							"nationality",
							value
						)
					}}
				/>
			</Antd.Form.Item>

			<Antd.Form.Item<FieldType>
				className={styles.form_item}
				label={t("message_gender")}
				name="gender"
				wrapperCol={{ span: 24 }}
				validateTrigger="onSubmit"
				rules={[
					{
						required: true,
						message: t("please_select_your_nationality"),
					},
				]}
			>
				<DvSelect
					options={genders}
					onSelect={(value) => {
						AppFunctions.setMembershipFormDataKey("gender", value)
					}}
				/>
			</Antd.Form.Item>

			<Antd.Form.Item<FieldType>
				className={styles.form_item}
				label={t("national_identification_number")}
				name="nationalId"
				wrapperCol={{ span: 24 }}
				validateTrigger="onSubmit"
				rules={[
					{
						required: true,
						message: t(
							"please_enter_your_national_identification_number"
						),
					},
				]}
			>
				<DvInput
					placeholder={t(
						"insert_your_national_identification_number"
					)}
					onChange={(e) => {
						AppFunctions.setMembershipFormDataKey(
							"nationalId",
							e.target.value
						)
					}}
				/>
			</Antd.Form.Item>

			<Antd.Form.Item<FieldType>
				className={`${styles.form_item} ${styles.receive_promotions_item}`}
				name="receivePromotionsByWhatsapp"
				valuePropName="checked"
			>
				<DvCheckbox
					className={styles.checkbox}
					onChange={(e) => {
						AppFunctions.setMembershipFormDataKey(
							"receivePromotionsByWhatsapp",
							e.target.checked
						)
					}}
				>
					{t("message_receive_notifications_byWhatsapp")}{" "}
					<strong>{t("privacy_policy_whatsapp")}</strong> {t("and")}{" "}
					{t("ours")} <strong>{t("privacy_policy")}</strong>.
				</DvCheckbox>
			</Antd.Form.Item>

			<DsButton
				id={MemberShipLayoutConstants.SUBMIT_FORM_BUTTON_ID}
				style={{ display: "none" }}
				htmlType="submit"
			/>
		</Antd.Form>
	)
}

export default React.memo(MembershipPersonalDetailsForm)
