import * as React from "react"
import * as Antd from "antd"
import styles from "./MembershipDigitalProfilesForm.module.scss"

import { AppFunctions } from "@others/AppFunctions.ts"
import { useNavigate } from "react-router-dom"
import { AppRoutes } from "@constants/AppRoutes.ts"
import { useTranslation } from "react-i18next"
import { MemberShipLayoutConstants } from "@components/layout/MembershipLayout/MembershipLayout.constants.ts"

import DsButton from "@components/general/DsButton/DsButton.tsx"
import DvInput from "@components/dataEntry/DvInput/DvInput.tsx"

type FieldType = {
	instagram?: string
	twitter?: string
	linkedIn?: string
}

interface IProps {
	className?: string
}

const MembershipDigitalProfilesForm: React.FC<IProps> = (props) => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	const initialValues: FieldType = {
		instagram: AppFunctions.getMembershipFormDataKey("instagram"),
		twitter: AppFunctions.getMembershipFormDataKey("twitter"),
		linkedIn: AppFunctions.getMembershipFormDataKey("linkedIn"),
	}

	const onFinish = ({ twitter, linkedIn, instagram }: FieldType) => {
		AppFunctions.setMembershipFormDataKey("twitter", twitter)
		AppFunctions.setMembershipFormDataKey("linkedIn", linkedIn)
		AppFunctions.setMembershipFormDataKey("instagram", instagram)

		navigate(AppRoutes.membershipFormStep3_references, {
			replace: true,
		})
	}

	return (
		<Antd.Form<FieldType>
			className={`${styles.membership_digital_profile_form} ${
				props.className || ""
			}`}
			name="basic"
			labelCol={{ span: 8 }}
			wrapperCol={{ span: 16 }}
			initialValues={initialValues}
			onFinish={onFinish}
			autoComplete="off"
			layout="vertical"
		>
			<header className={styles.header}>
				{t("message_we_can_meet_you_in_social_media")}:
			</header>

			<Antd.Form.Item<FieldType>
				className={styles.form_item}
				label="Instagram"
				name="instagram"
				wrapperCol={{ span: 24 }}
				validateTrigger="onSubmit"
			>
				<DvInput
					placeholder={t("insert_you_user")}
					onChange={(e) => {
						AppFunctions.setMembershipFormDataKey(
							"instagram",
							e.target.value
						)
					}}
				/>
			</Antd.Form.Item>

			<Antd.Form.Item<FieldType>
				className={styles.form_item}
				label="X (Twitter)"
				name="twitter"
				wrapperCol={{ span: 24 }}
				validateTrigger="onSubmit"
			>
				<DvInput
					placeholder={t("insert_you_user")}
					onChange={(e) => {
						AppFunctions.setMembershipFormDataKey(
							"twitter",
							e.target.value
						)
					}}
				/>
			</Antd.Form.Item>

			<Antd.Form.Item<FieldType>
				className={styles.form_item}
				label="LinkedIn"
				name="linkedIn"
				wrapperCol={{ span: 24 }}
				validateTrigger="onSubmit"
			>
				<DvInput
					placeholder={t("insert_you_user")}
					onChange={(e) => {
						AppFunctions.setMembershipFormDataKey(
							"linkedIn",
							e.target.value
						)
					}}
				/>
			</Antd.Form.Item>

			<DsButton
				id={MemberShipLayoutConstants.SUBMIT_FORM_BUTTON_ID}
				style={{ display: "none" }}
				htmlType="submit"
			/>
		</Antd.Form>
	)
}

export default React.memo(MembershipDigitalProfilesForm)
