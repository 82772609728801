import * as React from "react"
import styles from "./UserProfile.module.scss"

import { useLocation } from "react-router-dom"
import { AppNotification } from "@others/AppNotification.ts"

import UserInfo from "@pages/UserProfile/UserInfo/UserInfo.tsx"
import DownloadApp from "@pages/UserProfile/DownloadApp/DownloadApp.tsx"
import DsFooter from "@components/layout/DsFooter/DsFooter.tsx"
import DvMessage_1 from "@components/feedback/DvMessage_1/DvMessage_1.tsx"
import DvMessage_2 from "@components/feedback/DvMessage_2/DvMessage_2.tsx"
// import UpcomingBookings from "@pages/UserProfile/UpcomingBookings/UpcomingBookings.tsx"
// import Bookings from "@pages/UserProfile/Bookings/Bookings.tsx"

interface IProps {
	className?: string
}

const UserProfile: React.FC<IProps> = (props) => {
	const location = useLocation()
	const hasMembershipInProgress = location.state?.hasMembershipInProgress
	const hasAnApprovedMembership = location.state?.hasAnApprovedMembership

	const message1OpenRef = React.useRef(false)
	const message2OpenRef = React.useRef(false)

	React.useEffect(() => {
		if (hasMembershipInProgress && !message1OpenRef.current) {
			message1OpenRef.current = true
			AppNotification.warning({
				message: <DvMessage_1 />,
				placement: "bottom",
				duration: 600,
				onClose: () => {
					message1OpenRef.current = false
				},
			})
		}
	}, [hasMembershipInProgress])

	React.useEffect(() => {
		if (hasAnApprovedMembership && !message2OpenRef.current) {
			message2OpenRef.current = true
			AppNotification.warning({
				message: <DvMessage_2 />,
				placement: "bottom",
				duration: 600,
				onClose: () => {
					message2OpenRef.current = false
				},
			})
		}
	}, [hasAnApprovedMembership])

	return (
		<div className={`${styles.user_profile} ${props.className || ""}`}>
			<div className={styles.header}>
				<UserInfo />
				{/*<UpcomingBookings />*/}
			</div>

			<div className={styles.body}>
				<div className={styles.bookings_container}>
					{/*<Bookings />*/}

					<DownloadApp />
				</div>
			</div>

			<DsFooter />
		</div>
	)
}

export default React.memo(UserProfile)
