import * as React from "react"
import styles from "./StartApplying.module.scss"

import { useTranslation } from "react-i18next"
import { AppFunctions } from "@others/AppFunctions.ts"
import { useLocation } from "react-router-dom"

import useAuth from "@contexts/hooks/useAuth.tsx"

interface IProps {
	className?: string
}

const StartApplying: React.FC<IProps> = (props) => {
	const { loggedUser } = useAuth()
	const { t } = useTranslation()
	const location = useLocation()

	React.useEffect(() => {
		const plan = AppFunctions.getUrlParam("plan") || location.state?.plan
		const paymentFrequency =
			AppFunctions.getUrlParam("paymentFrequency") ||
			location.state?.paymentFrequency

		if (plan) AppFunctions.setMembershipFormDataKey("plan", plan)

		if (paymentFrequency)
			AppFunctions.setMembershipFormDataKey(
				"paymentFrequency",
				paymentFrequency
			)
	}, [])

	return (
		<div className={`${styles.start_applying} ${props.className || ""}`}>
			<h1 className={styles.title}>
				{t("hi")}
				{loggedUser?.name && `, ${loggedUser?.name}.`}
			</h1>
			<h3 className={styles.sub_title}>
				{t("message_starting_a_membership")}{" "}
				<strong>Distrito El Globo.</strong>
			</h3>

			<div className={styles.description}>
				<p className={styles.text}>
					{t("message_starting_a_membership_description_1")}
				</p>

				<p className={styles.text}>
					{t("message_starting_a_membership_description_2")}
				</p>
			</div>
		</div>
	)
}

export default React.memo(StartApplying)
