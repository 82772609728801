import * as React from "react"
import styles from "./MembershipFormStep3.module.scss"

import { useTranslation } from "react-i18next"
import { Outlet, useLocation } from "react-router-dom"
import { MEMBERSHIP_STEP3_TABS } from "@pages/MembershipFormStep3/MembershipFormStep3.constants.ts"
import { AppRoutes } from "@constants/AppRoutes.ts"
import { MemberShipLayoutConstants } from "@components/layout/MembershipLayout/MembershipLayout.constants.ts"

import DsSteps from "@components/feedback/DsSteps/DsSteps.tsx"
import DvMembershipFormTabs from "@components/navigation/DvMembershipFormTabs/DvMembershipFormTabs.tsx"

interface IProps {
	className?: string
}

const MembershipFormStep3: React.FC<IProps> = (props) => {
	const { t } = useTranslation()
	const location = useLocation()

	let subStep = 0

	switch (location.pathname) {
		case AppRoutes.membershipFormStep3_presentation:
			subStep = 1
			break
		case AppRoutes.membershipFormStep3_digital_profiles:
			subStep = 2
			break
		case AppRoutes.membershipFormStep3_references:
			subStep = 3
			break
	}

	return (
		<div
			className={`${styles.membership_form_step_3} ${props.className || ""}`}
		>
			<div className={styles.header}>
				<DsSteps
					currentStep={3}
					totalSteps={MemberShipLayoutConstants.totalSteps - 1}
					percentage={
						(subStep / 3) *
						(3 / MemberShipLayoutConstants.totalSteps) *
						100
					}
				/>

				<h1 className={styles.title}>{t("about_you")}</h1>

				<DvMembershipFormTabs
					selectedKeys={[
						location.pathname.split("/")?.pop() ||
							MEMBERSHIP_STEP3_TABS.presentation,
					]}
					items={[
						{
							className: "first",
							key: MEMBERSHIP_STEP3_TABS.presentation,
							label: t("presentation"),
						},
						{
							key: MEMBERSHIP_STEP3_TABS.digitalProfiles,
							label: t("digital_profiles"),
						},
						{
							className: "last",
							key: MEMBERSHIP_STEP3_TABS.references,
							label: t("references"),
						},
					]}
				/>
			</div>

			<Outlet />
		</div>
	)
}

export default React.memo(MembershipFormStep3)
