import * as React from "react"
import * as Antd from "antd"
import styles from "./MembershipPresentationForm.module.scss"

import { AppFunctions } from "@others/AppFunctions.ts"
import { useNavigate } from "react-router-dom"
import { AppRoutes } from "@constants/AppRoutes.ts"
import { useTranslation } from "react-i18next"
import { MemberShipLayoutConstants } from "@components/layout/MembershipLayout/MembershipLayout.constants.ts"

import DsButton from "@components/general/DsButton/DsButton.tsx"
import DvInput from "@components/dataEntry/DvInput/DvInput.tsx"

type FieldType = {
	presentation: string
}

interface IProps {
	className?: string
}

const MembershipPresentationForm: React.FC<IProps> = (props) => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	const initialValues: FieldType = {
		presentation: AppFunctions.getMembershipFormDataKey("presentation"),
	}

	const onFinish = ({ presentation }: FieldType) => {
		AppFunctions.setMembershipFormDataKey("presentation", presentation)

		navigate(AppRoutes.membershipFormStep3_digital_profiles, {
			replace: true,
		})
	}

	return (
		<Antd.Form<FieldType>
			className={`${styles.membership_presentation_form} ${
				props.className || ""
			}`}
			name="basic"
			labelCol={{ span: 8 }}
			wrapperCol={{ span: 16 }}
			initialValues={initialValues}
			onFinish={onFinish}
			autoComplete="off"
			layout="vertical"
		>
			<Antd.Form.Item<FieldType>
				className={styles.form_item}
				label={t("message_presentation")}
				name="presentation"
				wrapperCol={{ span: 24 }}
				validateTrigger="onSubmit"
				rules={[
					{
						required: true,
						message: t("please_tell_us_about_you"),
					},
				]}
			>
				<DvInput.TextArea
					className={styles.textarea}
					placeholder={t("tell_us_about_you")}
					onChange={(e) => {
						AppFunctions.setMembershipFormDataKey(
							"presentation",
							e.target.value
						)
					}}
				/>
			</Antd.Form.Item>

			<DsButton
				id={MemberShipLayoutConstants.SUBMIT_FORM_BUTTON_ID}
				style={{ display: "none" }}
				htmlType="submit"
			/>
		</Antd.Form>
	)
}

export default React.memo(MembershipPresentationForm)
