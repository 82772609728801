import * as React from "react"
import styles from "./MembershipFormStep1.module.scss"

import { useTranslation } from "react-i18next"
import { Outlet, useLocation } from "react-router-dom"
import { MEMBERSHIP_STEP1_TABS } from "@pages/MembershipFormStep1/MembershipFormStep1.constants.ts"
import { AppRoutes } from "@constants/AppRoutes.ts"
import { MemberShipLayoutConstants } from "@components/layout/MembershipLayout/MembershipLayout.constants.ts"

import DsSteps from "@components/feedback/DsSteps/DsSteps.tsx"
import DvMembershipFormTabs from "@components/navigation/DvMembershipFormTabs/DvMembershipFormTabs.tsx"

interface IProps {
	className?: string
}

const MembershipFormStep1: React.FC<IProps> = (props) => {
	const { t } = useTranslation()
	const location = useLocation()

	let subStep = 0

	switch (location.pathname) {
		case AppRoutes.membershipFormStep1_details:
			subStep = 0
			break
		case AppRoutes.membershipFormStep1_address:
			subStep = 1
			break
		case AppRoutes.membershipFormStep1_kind_of_membership:
			subStep = 2
			break
	}

	return (
		<div
			className={`${styles.membership_form_step_1} ${props.className || ""}`}
		>
			<div className={styles.header}>
				<DsSteps
					currentStep={1}
					totalSteps={MemberShipLayoutConstants.totalSteps - 1}
					percentage={
						(subStep / 2) *
						(1 / MemberShipLayoutConstants.totalSteps) *
						100
					}
				/>

				<h1 className={styles.title}>{t("your_basic_data")}</h1>

				<DvMembershipFormTabs
					selectedKeys={[
						location.pathname.split("/")?.pop() ||
							MEMBERSHIP_STEP1_TABS.details,
					]}
					items={[
						{
							className: "first",
							key: MEMBERSHIP_STEP1_TABS.details,
							label: t("details"),
						},
						{
							key: MEMBERSHIP_STEP1_TABS.address,
							label: t("address"),
						},
						{
							key: MEMBERSHIP_STEP1_TABS.kindOfMembership,
							label: t("kind_of_membership"),
							className: "last",
						},
					]}
				/>
			</div>

			<Outlet />
		</div>
	)
}

export default React.memo(MembershipFormStep1)
