import { AppLocalStorageKeys } from "@constants/AppLocalStorageKeys.ts"
import { AppRoutes } from "@constants/AppRoutes.ts"
import { IPostMembershipApplyBodyApi } from "@interfaces/IPostMembershipApplyBodyApi.ts"

import IndexedDB from "@services/IndexedDBService.ts"

export const AppFunctions = {
	isTokenExpiringSoon: (): boolean => {
		const expirationTime = localStorage.getItem(
			AppLocalStorageKeys.TOKEN_EXPIRATION_TIME
		)

		if (!expirationTime) return true

		const expirationTimeMs = parseInt(expirationTime, 10)
		const currentTime = Date.now()
		const timeLeft = expirationTimeMs - currentTime

		return timeLeft < 300000 // Return true if less than 5 minutes (300000 ms) remain
	},
	clearLoginDataFromLocalStorage: (): void => {
		localStorage.removeItem(AppLocalStorageKeys.TOKEN)
		localStorage.removeItem(AppLocalStorageKeys.REFRESH_TOKEN)
		localStorage.removeItem(AppLocalStorageKeys.TOKEN_EXPIRATION_TIME)
		IndexedDB.deleteDatabase()
	},
	isAuthenticated: (): boolean => {
		return !!localStorage.getItem(AppLocalStorageKeys.TOKEN)
	},
	isLoginRemembered: (): boolean => {
		return localStorage.getItem(AppLocalStorageKeys.REMEMBER_ME) !== "false"
	},
	goToLogin: (): void => {
		if (!window.location.pathname.includes(AppRoutes.login)) {
			window.location.href = `${AppRoutes.login}?lastUrl=${window.location.href}`
		}
	},
	getErrorApiDetail: (error: any) => {
		return error?.response?.data?.error?.details
	},
	setMembershipFormDataKey: (
		key: keyof IPostMembershipApplyBodyApi,
		value: string | number | undefined | boolean
	) => {
		if (value === undefined || value === "") return

		const formData = localStorage.getItem(
			AppLocalStorageKeys.APPLY_MEMBERSHIP_FORM_DATA
		)

		const formDataObj = formData ? JSON.parse(formData) : {}
		formDataObj[key] = value

		localStorage.setItem(
			AppLocalStorageKeys.APPLY_MEMBERSHIP_FORM_DATA,
			JSON.stringify(formDataObj)
		)
	},
	getMembershipFormDataKey: (key: keyof IPostMembershipApplyBodyApi) => {
		const formData = localStorage.getItem(
			AppLocalStorageKeys.APPLY_MEMBERSHIP_FORM_DATA
		)

		const formDataObj = formData ? JSON.parse(formData) : {}
		return formDataObj[key]
	},
	removeMembershipFormDataKey: (key: keyof IPostMembershipApplyBodyApi) => {
		const formData = localStorage.getItem(
			AppLocalStorageKeys.APPLY_MEMBERSHIP_FORM_DATA
		)

		const formDataObj = formData ? JSON.parse(formData) : {}
		delete formDataObj[key]

		localStorage.setItem(
			AppLocalStorageKeys.APPLY_MEMBERSHIP_FORM_DATA,
			JSON.stringify(formDataObj)
		)
	},
	getMembershipFormData: () => {
		const formData = localStorage.getItem(
			AppLocalStorageKeys.APPLY_MEMBERSHIP_FORM_DATA
		)

		return formData ? JSON.parse(formData) : {}
	},
	clearMembershipFormData: () => {
		localStorage.removeItem(AppLocalStorageKeys.APPLY_MEMBERSHIP_FORM_DATA)
	},
	getUserLocation: (
		success: (position: { lat: number; lng: number }) => void,
		error?: (error: GeolocationPositionError) => void
	) => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((position) => {
				success({
					lat: position.coords.latitude,
					lng: position.coords.longitude,
				})
			}, error)
		} else {
			if (error)
				error({
					code: 0,
					message: "Geolocation is not supported",
				} as GeolocationPositionError)
		}
	},
	loadGoogleMapsAPI: (onload: () => void) => {
		if (
			typeof window.google === "object" &&
			typeof window.google.maps === "object"
		) {
			onload()
			return
		}

		if (!document.getElementById("google-maps-script")) {
			const script = document.createElement("script")
			script.id = "google-maps-script"
			script.src = `https://maps.googleapis.com/maps/api/js?key=${import.meta.env.VITE_GOOGLE_MAPS_API_KEY}&libraries=places`
			script.async = true
			script.defer = true
			script.setAttribute("loading", "async")
			script.onload = () => onload()
			document.head.appendChild(script)
		}
	},
	getViteEnv: (key: string): string | undefined => {
		return import.meta.env[key]
	},
	openWebSendEmail: (
		email: string,
		subject: string = "",
		body: string = ""
	) => {
		return `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${subject}&body=${body}`
	},
	openWebSendWhatsapp: (
		countryCode: string,
		phoneNumber: string,
		message: string = ""
	) => {
		return `https://wa.me/${countryCode}${phoneNumber}?text=${encodeURIComponent(
			message
		)}`
	},
	getUrlParam: (paramName: string): string | null => {
		const urlParams = new URLSearchParams(window.location.search)
		return urlParams.get(paramName)
	},
	capitalizeFirstChar: (str: string) => {
		return str.trim().charAt(0).toUpperCase() + str.slice(1)
	},
}
