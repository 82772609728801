import * as React from "react"
import styles from "./MembershipSuccess.module.scss"

import { useTranslation } from "react-i18next"

import DsSteps from "@components/feedback/DsSteps/DsSteps.tsx"

interface IProps {
	className?: string
}

const MembershipSuccess: React.FC<IProps> = (props) => {
	const { t } = useTranslation()

	return (
		<div
			className={`${styles.membership_success} ${props.className || ""}`}
		>
			<div className={styles.header}>
				<DsSteps
					currentStep={6}
					totalSteps={6}
					percentage={(6 / 6) * 100}
				/>

				<h1 className={styles.title}>{t("everything_is_ready")}</h1>
			</div>

			<h1 className={styles.text}>
				{t("message_we_are_checking_your_application")}
			</h1>
		</div>
	)
}

export default React.memo(MembershipSuccess)
