import * as React from "react"
import * as Antd from "antd"
import styles from "./MembershipGloboRulesForm.module.scss"

import { AppFunctions } from "@others/AppFunctions.ts"
import { useNavigate } from "react-router-dom"
import { AppRoutes } from "@constants/AppRoutes.ts"
import { useTranslation } from "react-i18next"
import { MemberShipLayoutConstants } from "@components/layout/MembershipLayout/MembershipLayout.constants.ts"

import DsButton from "@components/general/DsButton/DsButton.tsx"
import DvCheckbox from "@components/dataEntry/DvCheckbox/DvCheckbox.tsx"
import useAuthCtx from "@contexts/hooks/useAuth.tsx"

type FieldType = {
	globoRulesAccepted: string
	receivePromotions: boolean
}

interface IProps {
	className?: string
}

const MembershipGloboRulesForm: React.FC<IProps> = (props) => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const { loggedUser } = useAuthCtx()

	const initialValues: FieldType = {
		globoRulesAccepted:
			AppFunctions.getMembershipFormDataKey("globoRulesAccepted"),
		receivePromotions:
			AppFunctions.getMembershipFormDataKey("receivePromotions") ??
			loggedUser?.receivePromotions,
	}

	const onFinish = ({ globoRulesAccepted, receivePromotions }: FieldType) => {
		AppFunctions.setMembershipFormDataKey(
			"globoRulesAccepted",
			globoRulesAccepted
		)
		AppFunctions.setMembershipFormDataKey(
			"receivePromotions",
			receivePromotions
		)

		navigate(AppRoutes.membershipFormStep5_sendMembership, {
			replace: true,
		})
	}

	return (
		<Antd.Form<FieldType>
			className={`${styles.membership_globo_rules_form} ${
				props.className || ""
			}`}
			name="basic"
			labelCol={{ span: 8 }}
			wrapperCol={{ span: 16 }}
			initialValues={initialValues}
			onFinish={onFinish}
			autoComplete="off"
			layout="vertical"
		>
			{/*TODO: update the rules*/}
			<p className={styles.text}>
				Fuga qui facere velit et quia eos non sit. Aliquid laboriosam
				illo autem ut. Dolor vel cum voluptas est inventore ut quos
				modi. Fugit labore maxime ratione. Earum nemo provident quia
				quia quia aut et quaerat totam. A tempora exercitationem rerum
				ut et sed. Hic tempora voluptates totam. Voluptatem dolores vero
				quo quae. Velit sit cumque omnis aspernatur. In rerum est vitae
				consequatur magnam. Et eaque repellat et nostrum tempora sed
				facere. Consequatur sunt non aut doloremque suscipit ut aut
				accusamus sit. Natus labore ea rerum excepturi. Rem impedit
				praesentium ratione deserunt nisi quam eum aut aspernatur.
			</p>

			<p className={styles.text}>
				Fuga qui facere velit et quia eos non sit. Aliquid laboriosam
				illo autem ut. Dolor vel cum voluptas est inventore ut quos
				modi. Fugit labore maxime ratione. Earum nemo provident quia
				quia quia aut et quaerat totam. A tempora exercitationem rerum
				ut et sed. Hic tempora voluptates totam. Voluptatem dolores vero
				quo quae. Velit sit cumque omnis aspernatur. In rerum est vitae
				consequatur magnam. Et eaque repellat et nostrum tempora sed
				facere. Consequatur sunt non aut doloremque suscipit ut aut
				accusamus sit. Natus labore ea rerum excepturi. Rem impedit
				praesentium ratione deserunt nisi quam eum aut aspernatur.
			</p>

			<Antd.Form.Item<FieldType>
				className={`${styles.form_item} ${styles.receive_promotions_item}`}
				name="globoRulesAccepted"
				valuePropName="checked"
				rules={[
					{
						validator: (_, value) =>
							value
								? Promise.resolve()
								: Promise.reject(
										new Error(
											t("you_have_to_accept_the_rules")
										)
									),
					},
				]}
			>
				<DvCheckbox
					className={styles.checkbox}
					onChange={(e) => {
						AppFunctions.setMembershipFormDataKey(
							"globoRulesAccepted",
							e.target.checked
						)
					}}
				>
					{t("message_accepting_globo_district_rules")}
				</DvCheckbox>
			</Antd.Form.Item>

			<Antd.Form.Item<FieldType>
				className={`${styles.form_item} ${styles.receive_promotions_item}`}
				name="receivePromotions"
				valuePropName="checked"
			>
				<DvCheckbox
					className={styles.checkbox}
					onChange={(e) => {
						AppFunctions.setMembershipFormDataKey(
							"receivePromotions",
							e.target.checked
						)
					}}
				>
					{t("message_receive_notifications")}
				</DvCheckbox>
			</Antd.Form.Item>

			<DsButton
				id={MemberShipLayoutConstants.SUBMIT_FORM_BUTTON_ID}
				style={{ display: "none" }}
				htmlType="submit"
			/>
		</Antd.Form>
	)
}

export default React.memo(MembershipGloboRulesForm)
