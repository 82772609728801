import * as React from "react"
import * as Antd from "antd"
import styles from "./MembershipSendForm.module.scss"

import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { MemberShipLayoutConstants } from "@components/layout/MembershipLayout/MembershipLayout.constants.ts"
import { AppFunctions } from "@others/AppFunctions.ts"
import { AppMessage } from "@others/AppMessage.ts"
import { AppRoutes } from "@constants/AppRoutes.ts"

import DsButton from "@components/general/DsButton/DsButton.tsx"
import membershipService from "@services/membershipService.ts"
import useMembershipLayoutCtx from "@contexts/hooks/useMembershipLayoutCtx.tsx"
import useAuth from "@contexts/hooks/useAuth.tsx"

interface IProps {
	className?: string
}

const MembershipGloboRulesForm: React.FC<IProps> = (props) => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const { setNextButtonLoading } = useMembershipLayoutCtx()
	const { setLoggedUser } = useAuth()

	const onFinish = () => {
		setNextButtonLoading(true)

		membershipService
			.apply(AppFunctions.getMembershipFormData())
			.then((response) => {
				if (response.membership_created) {
					AppFunctions.clearMembershipFormData()

					setLoggedUser((loggedUser) => {
						return {
							...loggedUser,
							hasMembershipInProgress: true,
						}
					})

					navigate(AppRoutes.membershipSuccess, {
						replace: true,
					})
				}
			})
			.catch(({ message }) => {
				AppMessage.error(message || t("something_went_wrong"))
			})
			.finally(() => {
				setNextButtonLoading(false)
			})
	}

	return (
		<Antd.Form
			className={`${styles.membership_globo_rules_form} ${
				props.className || ""
			}`}
			name="basic"
			labelCol={{ span: 8 }}
			wrapperCol={{ span: 16 }}
			onFinish={onFinish}
			autoComplete="off"
			layout="vertical"
		>
			<h1 className={styles.text}>
				{t("message_thanks_for_your_application")}
			</h1>

			<DsButton
				id={MemberShipLayoutConstants.SUBMIT_FORM_BUTTON_ID}
				style={{ display: "none" }}
				htmlType="submit"
			/>
		</Antd.Form>
	)
}

export default React.memo(MembershipGloboRulesForm)
