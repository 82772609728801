import * as React from "react"
import styles from "./DsLogoSDistrict.module.scss"

import { ReactComponent as LogoSDistrictSVG } from "@assets/svg/logo-s-district.svg"

interface IProps {
	className?: string
}

const DsLogoSDistrict: React.FC<IProps> = (props) => {
	return (
		<LogoSDistrictSVG
			className={`${styles.ds_logo_s_district} ${props.className || ""}`}
		/>
	)
}

export default React.memo(DsLogoSDistrict)
