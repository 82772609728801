import * as React from "react"
import * as Antd from "antd"
import styles from "./SignUpForm.module.scss"

import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import { AppRoutes } from "@constants/AppRoutes.ts"
import { AppMessage } from "@others/AppMessage.ts"
import { useListOfAllCountries } from "@hooks/useMeetUsOptions.tsx"
import { AppFunctions } from "@others/AppFunctions.ts"

import DsButton from "@components/general/DsButton/DsButton.tsx"
import DvCheckbox from "@components/dataEntry/DvCheckbox/DvCheckbox.tsx"
import DvSelect from "@components/dataEntry/DvSelect/DvSelect.tsx"
import authService from "@services/authService.ts"
import DvInput from "@components/dataEntry/DvInput/DvInput.tsx"
import DvInfoModal from "@components/feedback/DvInfoModal/DvInfoModal.tsx"
import PrivacyPolicy from "@components/dataEntry/SignUpForm/PrivacyPolicy/PrivacyPolicy.tsx"
import TermsAndConditions from "@components/dataEntry/SignUpForm/TermsAndConditions/TermsAndConditions.tsx"

type FieldType = {
	name?: string
	lastName?: string
	email?: string
	password?: string
	passwordConfirmation?: string
	phone?: string
	company?: string
	howDidYouMeetUs?: string
	receivePromotions?: boolean
}

interface IProps {
	className?: string
}

const SignUpForm: React.FC<IProps> = (props) => {
	const { t } = useTranslation()
	const { options, loading } = useListOfAllCountries()

	const navigate = useNavigate()
	const location = useLocation()

	const [isCreatingAccount, setIsCreatingAccount] = React.useState(false)

	const [privacyPolicyModalOpen, setPrivacyPolicyModalOpen] =
		React.useState(false)

	const [termsAndConditionsModalOpen, setTermsAndConditionsModalOpen] =
		React.useState(false)

	const initialValues: FieldType = {
		receivePromotions: true,
		email: location.state?.email,
	}

	const onFinish = (values: FieldType) => {
		setIsCreatingAccount(true)
		const currentDomain = window.location.origin

		const isSignUpDueExtMembershipApplicant =
			location.state?.plan && location.state?.paymentFrequency

		const forwardTo = !isSignUpDueExtMembershipApplicant
			? currentDomain + AppRoutes.infoAccountActivated
			: currentDomain +
				AppRoutes.membershipStartApplying +
				`?plan=${location.state.plan}&paymentFrequency=${location.state.paymentFrequency}`

		authService
			.signup({ ...values, forwardTo })
			.then((response) => {
				if (response.account_created && !response.email_sent) {
					AppMessage.warning(t("message_we_could_not_send_email"))
				} else {
					navigate(AppRoutes.infoDataCreateAccountReceived, {
						replace: true,
					})
				}
			})
			.catch(({ message }) => {
				AppMessage.error(message || t("something_went_wrong"))
			})
			.finally(() => {
				setIsCreatingAccount(false)
			})
	}

	return (
		<>
			<Antd.Form
				className={`${styles.signup_form} ${props.className || ""}`}
				name="basic"
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 16 }}
				initialValues={initialValues}
				onFinish={onFinish}
				autoComplete="off"
				layout="vertical"
			>
				<header className={styles.header}>
					<p>{t("message_let_start_with_a_new_account")}</p>
					<p>{t("lest_start")}</p>
				</header>

				<main className={styles.main}>
					<div className={styles.row}>
						<Antd.Form.Item<FieldType>
							className={styles.form_item}
							label={t("name")}
							name="name"
							wrapperCol={{ span: 24 }}
							validateTrigger="onSubmit"
							rules={[
								{
									required: true,
									message: t("please_enter_your_name"),
								},
							]}
						>
							<DvInput placeholder={t("insert_your_name")} />
						</Antd.Form.Item>

						<Antd.Form.Item<FieldType>
							className={styles.form_item}
							label={t("lastName")}
							name="lastName"
							wrapperCol={{ span: 24 }}
							validateTrigger="onSubmit"
							rules={[
								{
									required: true,
									message: t("please_enter_your_lastName"),
								},
							]}
						>
							<DvInput placeholder={t("insert_your_lastName")} />
						</Antd.Form.Item>

						<Antd.Form.Item<FieldType>
							className={styles.form_item}
							label={t("email")}
							name="email"
							wrapperCol={{ span: 24 }}
							validateTrigger="onSubmit"
							rules={[
								{
									required: true,
									message: t("please_enter_your_email"),
								},
								{
									type: "email",
									message: t("your_email_is_not_valid"),
								},
							]}
						>
							<DvInput placeholder={t("insert_your_email")} />
						</Antd.Form.Item>

						<Antd.Form.Item<FieldType>
							className={`${styles.form_item} ${styles.password_item}`}
							label={t("password")}
							name="password"
							wrapperCol={{ span: 24 }}
							validateTrigger="onSubmit"
							rules={[
								{
									required: true,
									message: t("please_enter_your_password"),
								},
							]}
						>
							<DvInput.Password
								placeholder={t("insert_your_password")}
							/>
						</Antd.Form.Item>

						<Antd.Form.Item<FieldType>
							className={`${styles.form_item} ${styles.password_item}`}
							label={t("confirm_password")}
							name="passwordConfirmation"
							wrapperCol={{ span: 24 }}
							validateTrigger="onSubmit"
							rules={[
								{
									required: true,
									message: t("please_enter_your_password"),
								},
							]}
						>
							<DvInput.Password
								placeholder={t("insert_your_password")}
							/>
						</Antd.Form.Item>

						<Antd.Form.Item<FieldType>
							className={styles.form_item}
							label={t("phone")}
							name="phone"
							wrapperCol={{ span: 24 }}
							validateTrigger="onSubmit"
							rules={[
								{
									required: true,
									message: t("please_enter_your_phone"),
								},
								{
									pattern: /^\+?[1-9]\d{1,14}$/, // E.164 format: +[country code][number]
									message: t(
										"message_your_phone_is_not_valid"
									),
								},
							]}
						>
							<DvInput placeholder={t("insert_your_phone")} />
						</Antd.Form.Item>
					</div>

					<Antd.Divider className={styles.divider} />

					<div className={styles.row}>
						<Antd.Form.Item<FieldType>
							className={styles.form_item}
							label={t("company")}
							name="company"
							wrapperCol={{ span: 24 }}
							validateTrigger="onSubmit"
							rules={[
								{
									required: true,
									message: t("please_enter_your_company"),
								},
							]}
						>
							<DvInput placeholder={t("insert_your_company")} />
						</Antd.Form.Item>

						<Antd.Form.Item<FieldType>
							className={`${styles.form_item} ${styles.how_did_you_meet_us_item}`}
							label={t("how_did_you_meet_us")}
							name="howDidYouMeetUs"
							wrapperCol={{ span: 24 }}
							validateTrigger="onSubmit"
							rules={[
								{
									required: true,
									message: t("please_select_an_option"),
								},
							]}
						>
							<DvSelect options={options} loading={loading} />
						</Antd.Form.Item>
					</div>

					<p className={styles.text}>
						{t("message_accepting_terms")}{" "}
						<span onClick={() => setPrivacyPolicyModalOpen(true)}>
							{t("privacy_policy")}
						</span>{" "}
						{t("and")}{" "}
						<span
							onClick={() => setTermsAndConditionsModalOpen(true)}
						>
							{t("terms_and_conditions")}
						</span>
						.
					</p>

					<div className={styles.row}>
						<Antd.Form.Item<FieldType>
							className={`${styles.form_item} ${styles.receive_promotions_item}`}
							name="receivePromotions"
							valuePropName="checked"
						>
							<DvCheckbox className={styles.checkbox}>
								{t("message_receive_notifications")}
							</DvCheckbox>
						</Antd.Form.Item>
					</div>
				</main>

				<div className={styles.footer}>
					<DsButton
						className={styles.action_button}
						htmlType="submit"
						loading={isCreatingAccount}
					>
						{t("create_account")}
					</DsButton>
				</div>
			</Antd.Form>

			<DvInfoModal
				open={privacyPolicyModalOpen}
				title={AppFunctions.capitalizeFirstChar(t("privacy_policy"))}
				onClose={() => setPrivacyPolicyModalOpen(false)}
			>
				<PrivacyPolicy />
			</DvInfoModal>

			<DvInfoModal
				open={termsAndConditionsModalOpen}
				title={AppFunctions.capitalizeFirstChar(
					t("terms_and_conditions")
				)}
				onClose={() => setTermsAndConditionsModalOpen(false)}
			>
				<TermsAndConditions />
			</DvInfoModal>
		</>
	)
}

export default React.memo(SignUpForm)
