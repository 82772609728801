import * as React from "react"
import styles from "./MembershipPlans.module.scss"

import DsPlanTable, {
	IDsPlanTableProps,
} from "@components/dataDisplay/DsPlanTable/DsPlanTable.tsx"

import { useTranslation } from "react-i18next"
import { usePlanData } from "@hooks/usePlanData.tsx"

interface IProps {
	className?: string
}

const MembershipPlans: React.FC<IProps> = (props) => {
	const { t } = useTranslation()
	const { planData } = usePlanData()

	const columns = React.useMemo(() => {
		if (!planData?.plans?.length) return []

		return planData?.plans.map<IDsPlanTableProps["columns"][0]>(
			({ name, type }) => ({
				planName: name,
				planType: type,
			})
		)
	}, [planData])

	const rows = React.useMemo(() => {
		if (!planData?.plans?.length) return []

		return planData?.plans.reduce<IDsPlanTableProps["rows"]>(
			(acc, plan) => {
				plan.benefits.forEach(({ name, enabled }, idx) => {
					if (acc[idx]) {
						acc[idx].benefitsEnabled.push(enabled)
					} else {
						acc.push({
							benefitName: name,
							benefitsEnabled: [enabled],
						})
					}
				})

				return acc
			},
			[]
		)
	}, [planData])

	const billing = React.useMemo(() => {
		if (!planData?.plans?.length) return []

		return planData?.plans.map<IDsPlanTableProps["billing"][0]>(
			({ monthlyBill, annualBill, name, id }) => ({
				id,
				name,
				monthlyBill,
				annualBill,
			})
		)
	}, [planData])

	return (
		<div className={`${styles.membership_plans} ${props.className || ""}`}>
			<h1 className={styles.heading}>
				{t("select_your_membership_plan")}:
			</h1>

			{planData && (
				<DsPlanTable
					className={styles.plan_table}
					columns={columns || []}
					rows={rows || []}
					billing={billing || []}
					defaultPlan={planData.defaultPlan}
					defaultPaymentFrequency={planData.defaultPaymentFrequency}
				/>
			)}
		</div>
	)
}

export default React.memo(MembershipPlans)
