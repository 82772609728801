import * as React from "react"
import styles from "./DsLogoBlancoElGlobo.module.scss"

import { ReactComponent as LogoBlancoElGloboHorizontalMobile } from "@assets/svg/logo-blanco-el-globo-horizontal-mobile.svg"
import { ReactComponent as LogoBlancoElGloboHorizontalDesktop } from "@assets/svg/logo-blanco-el-globo-horizontal-desktop.svg"
import { ReactComponent as LogoBlancoElGloboVerticalMobile } from "@assets/svg/logo-blanco-el-globo-vertical-mobile.svg"
import { AppExternalLinks } from "@constants/AppExternalLinks.ts"

import useResponsiveToolCtx from "@contexts/hooks/useResponsiveToolCtx.tsx"

interface IProps {
	className?: string
	type: "horizontal" | "vertical"
}

const DsLogoBlancoElGlobo: React.FC<IProps> = (props) => {
	const { isDesktop, isMobile } = useResponsiveToolCtx()

	const goGloboHome = () => {
		window.location.href = AppExternalLinks().globo_home
	}

	return (
		<>
			{props.type === "horizontal" && (
				<>
					{isMobile && (
						<LogoBlancoElGloboHorizontalMobile
							className={`${styles.ds_logo_blanco_el_globo} ${props.className || ""} mobile`}
							onClick={goGloboHome}
						/>
					)}
					{isDesktop && (
						<LogoBlancoElGloboHorizontalDesktop
							className={`${styles.ds_logo_blanco_el_globo} ${props.className || ""} desktop`}
							onClick={goGloboHome}
						/>
					)}
				</>
			)}

			{props.type === "vertical" && (
				<>
					{isMobile && (
						<LogoBlancoElGloboVerticalMobile
							className={`${styles.ds_logo_blanco_el_globo} ${props.className || ""} mobile`}
							onClick={goGloboHome}
						/>
					)}
					{isDesktop && (
						<LogoBlancoElGloboVerticalMobile
							className={`${styles.ds_logo_blanco_el_globo} ${props.className || ""} desktop`}
							onClick={goGloboHome}
						/>
					)}
				</>
			)}
		</>
	)
}

export default React.memo(DsLogoBlancoElGlobo)
