import * as React from "react"
import styles from "./CardsBackground.module.scss"

import { ReactComponent as Group12 } from "@assets/svg/group-12.svg"

interface IProps {
	className?: string
}

const CardsBackground: React.FC<IProps> = (props) => {
	return (
		<div className={`${styles.cards_background} ${props.className || ""}`}>
			<div className={styles.background_image_container}>
				<Group12 className={styles.background_image} />
			</div>
		</div>
	)
}

export default React.memo(CardsBackground)
