import * as React from "react"
import * as Antd from "antd"
import * as AntdIcons from "@ant-design/icons"
import styles from "./PersonalDataForm.module.scss"

import { useTranslation } from "react-i18next"
import { AppRoutes } from "@constants/AppRoutes.ts"
import { useNavigate } from "react-router-dom"
import { AppMessage } from "@others/AppMessage.ts"

import DsButton from "@components/general/DsButton/DsButton.tsx"
import useAuthCtx from "@contexts/hooks/useAuth.tsx"
import DvPhotoUpload from "@components/dataEntry/DvPhotoUpload/DvPhotoUpload.tsx"
import DvInput from "@components/dataEntry/DvInput/DvInput.tsx"
import PhotoUploadFormItem from "@components/layout/PhotoUploadFormItem/PhotoUploadFormItem.tsx"
import userService from "@services/userService.ts"

type FieldType = {
	name: string
	lastName: string
	email: string
	phone: string
	profilePhoto?: string
}

interface IProps {
	className?: string
}

const PersonalDataForm: React.FC<IProps> = (props) => {
	const { t } = useTranslation()
	const { loggedUser, setLoggedUser } = useAuthCtx()
	const navigate = useNavigate()
	const formRef = React.useRef<Antd.FormInstance<FieldType> | null>(null)

	const [saveChangesLoading, setSaveChangesLoading] = React.useState(false)

	const [profilePhoto, setProfilePhoto] = React.useState<string | undefined>(
		loggedUser?.profilePhoto
	)

	const initialValues: FieldType = {
		name: loggedUser?.name || "",
		lastName: loggedUser?.lastName || "",
		email: loggedUser?.email || "",
		phone: loggedUser?.phone || "",
		profilePhoto: profilePhoto,
	}

	const onFinish = ({ name, lastName, email, phone }: FieldType) => {
		setSaveChangesLoading(true)
		userService
			.updateUserData({
				name,
				lastName,
				email,
				phone,
				profilePhoto: profilePhoto,
			})
			.then(({ user }) => {
				setLoggedUser(user)
				AppMessage.info(t("changes_saved_successfully"))
				navigate(AppRoutes.userSettings)
			})
			.catch(({ message }) => {
				AppMessage.error(message || t("something_went_wrong"))
			})
			.finally(() => {
				setSaveChangesLoading(false)
			})
	}

	React.useEffect(() => {
		if (loggedUser?.profilePhoto) setProfilePhoto(loggedUser?.profilePhoto)
	}, [loggedUser?.profilePhoto])

	return (
		<div
			className={`${styles.personal_data_form} ${props.className || ""}`}
		>
			<main className={styles.main}>
				<div className={styles.header}>
					<AntdIcons.ArrowLeftOutlined
						className={styles.icon}
						onClick={() => navigate(AppRoutes.userSettings)}
					/>

					<h1 className={styles.title}>{t("personal_data")}</h1>
				</div>

				<Antd.Form<FieldType>
					className={`${styles.user_personal_data_form} ${
						props.className || ""
					}`}
					name="basic"
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 16 }}
					initialValues={initialValues}
					onFinish={onFinish}
					autoComplete="off"
					layout="vertical"
					ref={formRef}
				>
					<Antd.Form.Item<FieldType>
						className={styles.form_item}
						label={t("name")}
						name="name"
						wrapperCol={{ span: 24 }}
						validateTrigger="onSubmit"
						rules={[
							{
								required: true,
								message: t("please_enter_your_name"),
							},
						]}
					>
						<DvInput placeholder={t("insert_your_name")} />
					</Antd.Form.Item>

					<Antd.Form.Item<FieldType>
						className={styles.form_item}
						label={t("lastName")}
						name="lastName"
						wrapperCol={{ span: 24 }}
						validateTrigger="onSubmit"
						rules={[
							{
								required: true,
								message: t("please_enter_your_lastName"),
							},
						]}
					>
						<DvInput placeholder={t("insert_your_lastName")} />
					</Antd.Form.Item>

					<Antd.Form.Item<FieldType>
						className={styles.form_item}
						label={t("email")}
						name="email"
						wrapperCol={{ span: 24 }}
						validateTrigger="onSubmit"
						rules={[
							{
								required: true,
								message: t("please_enter_your_email"),
							},
							{
								type: "email",
								message: t("your_email_is_not_valid"),
							},
						]}
					>
						<DvInput placeholder={t("insert_your_email")} />
					</Antd.Form.Item>

					<Antd.Form.Item<FieldType>
						className={styles.form_item}
						label={t("phone")}
						name="phone"
						wrapperCol={{ span: 24 }}
						validateTrigger="onSubmit"
						rules={[
							{
								required: true,
								message: t("please_enter_your_phone"),
							},
							{
								pattern: /^\+?[1-9]\d{1,14}$/, // E.164 format: +[country code][number]
								message: t("message_your_phone_is_not_valid"),
							},
						]}
					>
						<DvInput placeholder={t("insert_your_phone")} />
					</Antd.Form.Item>

					<PhotoUploadFormItem>
						<DvPhotoUpload
							initialBase64Image={initialValues.profilePhoto}
							base64Image={profilePhoto}
							onSuccess={({ base64Image }) =>
								setProfilePhoto(base64Image)
							}
						/>
					</PhotoUploadFormItem>
				</Antd.Form>
			</main>

			<footer className={styles.footer}>
				<DsButton
					className={styles.footer_button}
					loading={saveChangesLoading}
					onClick={() => formRef.current?.submit()}
				>
					{t("save_changes")}
				</DsButton>
			</footer>
		</div>
	)
}

export default React.memo(PersonalDataForm)
