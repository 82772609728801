import * as React from "react"
import styles from "./MembershipKindOfForm.module.scss"

import { MemberShipLayoutConstants } from "@components/layout/MembershipLayout/MembershipLayout.constants.ts"
import { AppRoutes } from "@constants/AppRoutes.ts"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { AppFunctions } from "@others/AppFunctions.ts"
import { IPlanData, usePlanData } from "@hooks/usePlanData.tsx"

import PlanDetailItem from "@components/dataEntry/MembershipKindOfForm/PlanDetailItem/PlanDetailItem.tsx"
import DsButton from "@components/general/DsButton/DsButton.tsx"
import DsPlanInfoCard from "@components/dataDisplay/DsPlanInfoCard/DsPlanInfoCard.tsx"

type FieldType = {
	planId: IPlanData["plans"][0]["id"]
	paymentFrequency:
		| IPlanData["plans"][0]["monthlyBill"]["frequency"]
		| IPlanData["plans"][0]["annualBill"]["frequency"]
}

interface IProps {
	className?: string
}

const MembershipKindOfForm: React.FC<IProps> = (props) => {
	const { t } = useTranslation()
	const { planData } = usePlanData()
	const navigate = useNavigate()

	const initialValues: FieldType = {
		planId: AppFunctions.getMembershipFormDataKey("plan"),
		paymentFrequency:
			AppFunctions.getMembershipFormDataKey("paymentFrequency"),
	}

	const [planId, setPlanId] = React.useState<FieldType["planId"]>(
		initialValues.planId
	)

	const [paymentFrequency, setPaymentFrequency] = React.useState<
		FieldType["paymentFrequency"]
	>(initialValues.paymentFrequency)

	const monthlyBill = planData?.plans.find(
		(plan) => plan.id === planId
	)?.monthlyBill

	const annualBill = planData?.plans.find(
		(plan) => plan.id === planId
	)?.annualBill

	const onFinish = () => {
		AppFunctions.setMembershipFormDataKey("plan", planId)

		AppFunctions.setMembershipFormDataKey(
			"paymentFrequency",
			paymentFrequency
		)

		navigate(AppRoutes.membershipFormStep2_personalDetails, {
			replace: true,
		})
	}

	React.useEffect(() => {
		if (planData && !initialValues.planId) setPlanId(planData.defaultPlan)

		if (planData && !initialValues.paymentFrequency)
			setPaymentFrequency(planData?.defaultPaymentFrequency)
	}, [planData])

	return (
		<div
			className={`${styles.membership_kind_of_form} ${props.className || ""}`}
		>
			<div className={styles.column}>
				<div className={styles.frequency_payment_actions}>
					<label>{t("choose_your_payment_method")}</label>

					<div className={styles.button_container}>
						<DsButton
							className={`${styles.payment_button} ${paymentFrequency !== "monthly" && styles.disabled}`}
							onClick={() => {
								setPaymentFrequency("monthly")

								AppFunctions.setMembershipFormDataKey(
									"paymentFrequency",
									"monthly"
								)
							}}
						>
							{t("monthly")}
						</DsButton>

						<DsButton
							className={`${styles.payment_button} ${paymentFrequency !== "annual" && styles.disabled}`}
							onClick={() => {
								setPaymentFrequency("annual")

								AppFunctions.setMembershipFormDataKey(
									"paymentFrequency",
									"annual"
								)
							}}
						>
							{t("annual")}
						</DsButton>
					</div>
				</div>

				<div className={styles.payment_details}>
					{paymentFrequency === "monthly" && monthlyBill && (
						<>
							<PlanDetailItem
								title={t("payment_1")}
								subtitle={t("monthly_plus_access_fee")}
								currency="US$"
								value={monthlyBill.firstPayment}
							/>

							<PlanDetailItem
								title={t("payment_2_12")}
								currency="US$"
								value={monthlyBill.remainingPayments}
								unit="c/u"
								strongBorder
							/>

							<PlanDetailItem
								title={t("total")}
								currency="US$"
								value={monthlyBill.total}
								strongText
							/>
						</>
					)}

					{paymentFrequency === "annual" && annualBill && (
						<>
							<PlanDetailItem
								title={t("annuity")}
								currency="US$"
								value={annualBill.annuity}
							/>

							<PlanDetailItem
								title={t("access_fee")}
								currency="US$"
								value={annualBill.accessFee}
								strongBorder
							/>

							<PlanDetailItem
								title={t("total")}
								currency="US$"
								value={annualBill.total}
								strongText
							/>
						</>
					)}
				</div>
			</div>

			{planData?.plans.map(({ id, name, type, info }) => {
				return (
					<DsPlanInfoCard
						key={id}
						name={name}
						type={type}
						info={info}
						selected={id === planId}
						onCardClick={() => {
							setPlanId(id)
							AppFunctions.setMembershipFormDataKey("plan", id)
						}}
					/>
				)
			})}

			<DsButton
				style={{ display: "none" }}
				id={MemberShipLayoutConstants.SUBMIT_FORM_BUTTON_ID}
				className={styles.login_button}
				htmlType="submit"
				onClick={onFinish}
			/>
		</div>
	)
}

export default React.memo(MembershipKindOfForm)
