import * as React from "react"
import * as Antd from "antd"
import styles from "./MembershipReferencesForm.module.scss"

import { AppFunctions } from "@others/AppFunctions.ts"
import { useNavigate } from "react-router-dom"
import { AppRoutes } from "@constants/AppRoutes.ts"
import { useTranslation } from "react-i18next"
import { MemberShipLayoutConstants } from "@components/layout/MembershipLayout/MembershipLayout.constants.ts"

import DsButton from "@components/general/DsButton/DsButton.tsx"
import DvCheckbox from "@components/dataEntry/DvCheckbox/DvCheckbox.tsx"
import DvInput from "@components/dataEntry/DvInput/DvInput.tsx"

type FieldType = {
	referenceName?: string
	referenceEmail?: string
	doNotKnowAnyone?: boolean
}

interface IProps {
	className?: string
}

const MembershipReferencesForm: React.FC<IProps> = (props) => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	const initialValues: FieldType = {
		referenceName: AppFunctions.getMembershipFormDataKey("referenceName"),
		referenceEmail: AppFunctions.getMembershipFormDataKey("referenceEmail"),
		doNotKnowAnyone:
			AppFunctions.getMembershipFormDataKey("doNotKnowAnyone") ?? false,
	}

	const [checked, setChecked] = React.useState(initialValues.doNotKnowAnyone)

	const onFinish = ({
		referenceName,
		referenceEmail,
		doNotKnowAnyone,
	}: FieldType) => {
		AppFunctions.setMembershipFormDataKey("referenceName", referenceName)
		AppFunctions.setMembershipFormDataKey("referenceEmail", referenceEmail)

		AppFunctions.setMembershipFormDataKey(
			"doNotKnowAnyone",
			doNotKnowAnyone
		)

		navigate(AppRoutes.membershipFormStep4_globoRules, {
			replace: true,
		})
	}

	return (
		<Antd.Form<FieldType>
			className={`${styles.membership_reference_form} ${
				props.className || ""
			}`}
			name="basic"
			labelCol={{ span: 8 }}
			wrapperCol={{ span: 16 }}
			initialValues={initialValues}
			onFinish={onFinish}
			autoComplete="off"
			layout="vertical"
		>
			<header className={styles.header}>
				{t("message_if_you_no_a_member_of")}
				<strong>Distrito El Globo</strong>,{" "}
				{t("message_you_can_add_it_here")}:
			</header>

			<Antd.Form.Item<FieldType>
				className={styles.form_item}
				name="doNotKnowAnyone"
				valuePropName="checked"
				wrapperCol={{ span: 24 }}
				validateTrigger="onSubmit"
			>
				<DvCheckbox
					className={styles.checkbox}
					onChange={(e) => {
						const isChecked = e.target.checked

						setChecked(isChecked)

						AppFunctions.setMembershipFormDataKey(
							"doNotKnowAnyone",
							isChecked
						)

						if (isChecked) {
							AppFunctions.removeMembershipFormDataKey(
								"referenceName"
							)
							AppFunctions.removeMembershipFormDataKey(
								"referenceEmail"
							)
						}
					}}
				>
					{t("message_i_dont_know_anyone")}{" "}
					<strong>Distrito El Globo</strong> {t("yet")}.
				</DvCheckbox>
			</Antd.Form.Item>

			<Antd.Form.Item<FieldType>
				className={styles.form_item}
				label={t("name")}
				name={!checked ? "referenceName" : undefined}
				wrapperCol={{ span: 24 }}
				validateTrigger="onSubmit"
				rules={[
					{
						required: true,
						message: t("please_enter_their_name"),
					},
				]}
			>
				<DvInput
					placeholder={t("insert_their_name")}
					disabled={checked}
					onChange={(e) => {
						AppFunctions.setMembershipFormDataKey(
							"referenceName",
							e.target.value
						)
					}}
				/>
			</Antd.Form.Item>

			<Antd.Form.Item<FieldType>
				className={styles.form_item}
				label={t("email")}
				name={!checked ? "referenceEmail" : undefined}
				wrapperCol={{ span: 24 }}
				validateTrigger="onSubmit"
				rules={[
					{
						required: true,
						message: t("please_enter_their_email"),
					},
					{
						type: "email",
						message: t("your_email_is_not_valid"),
					},
				]}
			>
				<DvInput
					placeholder={t("insert_their_email")}
					disabled={checked}
					onChange={(e) => {
						AppFunctions.setMembershipFormDataKey(
							"referenceEmail",
							e.target.value
						)
					}}
				/>
			</Antd.Form.Item>

			<DsButton
				id={MemberShipLayoutConstants.SUBMIT_FORM_BUTTON_ID}
				style={{ display: "none" }}
				htmlType="submit"
			/>
		</Antd.Form>
	)
}

export default React.memo(MembershipReferencesForm)
