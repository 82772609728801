import * as Antd from "antd"

const AntdMessage = Antd.message

AntdMessage.config({
	top: 72,
})

const infoDuration = 5
const successDuration = 5

const warningDuration = 8
const errorDuration = 8

export const AppMessage = {
	info: async (message: string) => {
		return AntdMessage.info({
			content: message,
			duration: infoDuration,
		})
	},
	success: async (message: string) => {
		return AntdMessage.success({
			content: message,
			duration: successDuration,
		})
	},
	warning: async (message: string) => {
		return AntdMessage.warning({
			content: message,
			duration: warningDuration,
		})
	},
	error: async (message: string) => {
		return AntdMessage.error({
			content: message,
			duration: errorDuration,
		})
	},
}
