import * as React from "react"
import * as Antd from "antd"
import styles from "./ValidateAccountForm.module.scss"

import { useTranslation } from "react-i18next"
import { Navigate, useNavigate } from "react-router-dom"
import { AppRoutes } from "@constants/AppRoutes.ts"
import { AppMessage } from "@others/AppMessage.ts"
import { AppFunctions } from "@others/AppFunctions.ts"

import DsButton from "@components/general/DsButton/DsButton.tsx"
import authService from "@services/authService.ts"
import DvInput from "@components/dataEntry/DvInput/DvInput.tsx"
import useAuthCtx from "@contexts/hooks/useAuth.tsx"

type FieldType = {
	email?: string
}

interface IProps {
	className?: string
}

const ValidateAccountForm: React.FC<IProps> = (props) => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const { isLogged, loggedUser } = useAuthCtx()

	const [isCheckingEmail, setIsCheckingEmail] = React.useState(false)

	const plan = AppFunctions.getUrlParam("plan")
	const paymentFrequency = AppFunctions.getUrlParam("paymentFrequency")

	if (plan && paymentFrequency && isLogged) {
		if (
			!loggedUser?.hasAnApprovedMembership &&
			!loggedUser?.hasMembershipInProgress
		) {
			return (
				<Navigate
					to={AppRoutes.membershipStartApplying}
					replace
					state={{ plan, paymentFrequency }}
				/>
			)
		} else if (loggedUser?.hasAnApprovedMembership) {
			return (
				<Navigate
					to={AppRoutes.userProfile}
					replace
					state={{ hasAnApprovedMembership: true }}
				/>
			)
		} else if (loggedUser?.hasMembershipInProgress) {
			return (
				<Navigate
					to={AppRoutes.userProfile}
					replace
					state={{ hasMembershipInProgress: true }}
				/>
			)
		}
	}

	const onFinish = ({ email }: FieldType) => {
		setIsCheckingEmail(true)

		authService
			.checkEmail({ email })
			.then((response) => {
				if (response.accountExists) {
					AppMessage.success(t("message_account_exists"))
					navigate(AppRoutes.login, {
						replace: true,
						state: { email, plan, paymentFrequency },
					})
				} else {
					navigate(AppRoutes.signup, {
						replace: true,
						state: { email, plan, paymentFrequency },
					})
				}
			})
			.catch(({ message }) => {
				AppMessage.error(message || t("something_went_wrong"))
			})
			.finally(() => {
				setIsCheckingEmail(false)
			})
	}

	return (
		<Antd.Form
			className={`${styles.validate_account_form} ${props.className || ""}`}
			name="basic"
			labelCol={{ span: 8 }}
			wrapperCol={{ span: 16 }}
			initialValues={{ remember: true }}
			onFinish={onFinish}
			autoComplete="off"
			layout="vertical"
		>
			<div className={styles.top}>
				<header className={styles.header}>
					{t("instructions_to_validate_account")}
				</header>

				<main className={styles.body}>
					<Antd.Form.Item<FieldType>
						className={styles.form_item}
						label={t("email")}
						name="email"
						wrapperCol={{ span: 24 }}
						validateTrigger="onSubmit"
						rules={[
							{
								required: true,
								message: t("please_enter_your_email"),
							},
							{
								type: "email",
								message: t("your_email_is_not_valid"),
							},
						]}
					>
						<DvInput placeholder={t("insert_your_email")} />
					</Antd.Form.Item>
				</main>
			</div>

			<div className={styles.footer}>
				<DsButton
					className={styles.action_button}
					htmlType="submit"
					loading={isCheckingEmail}
				>
					{t("continue")}
				</DsButton>

				<DsButton
					type="secondary"
					className={styles.action_button}
					onClick={() => {
						navigate(AppRoutes.login, { replace: true })
					}}
				>
					{t("back")}
				</DsButton>
			</div>
		</Antd.Form>
	)
}

export default React.memo(ValidateAccountForm)
