import * as React from "react"
import * as Antd from "antd"
import dayjs from "dayjs"
import styles from "./MembershipDetailsForm.module.scss"

import { useTranslation } from "react-i18next"
import { MemberShipLayoutConstants } from "@components/layout/MembershipLayout/MembershipLayout.constants.ts"
import { AppFunctions } from "@others/AppFunctions.ts"
import { useNavigate } from "react-router-dom"
import { AppRoutes } from "@constants/AppRoutes.ts"

import DsButton from "@components/general/DsButton/DsButton.tsx"
import useAuthCtx from "@contexts/hooks/useAuth.tsx"
import DvInput from "@components/dataEntry/DvInput/DvInput.tsx"

type FieldType = {
	name?: string
	lastName?: string
	email?: string
	birthdate?: dayjs.Dayjs
}

interface IProps {
	className?: string
}

const MembershipDetailsForm: React.FC<IProps> = (props) => {
	const { t } = useTranslation()
	const { loggedUser } = useAuthCtx()
	const navigate = useNavigate()

	const initialValues: FieldType = {
		name: AppFunctions.getMembershipFormDataKey("name") || loggedUser?.name,

		lastName:
			AppFunctions.getMembershipFormDataKey("lastName") ||
			loggedUser?.lastName,

		email:
			AppFunctions.getMembershipFormDataKey("email") || loggedUser?.email,

		birthdate: AppFunctions.getMembershipFormDataKey("birthdate")
			? dayjs(AppFunctions.getMembershipFormDataKey("birthdate"))
			: undefined,
	}

	const onFinish = ({ name, lastName, email, birthdate }: FieldType) => {
		AppFunctions.setMembershipFormDataKey("name", name)
		AppFunctions.setMembershipFormDataKey("lastName", lastName)
		AppFunctions.setMembershipFormDataKey("email", email)

		AppFunctions.setMembershipFormDataKey(
			"birthdate",
			birthdate?.toISOString()
		)

		navigate(AppRoutes.membershipFormStep1_address, {
			replace: true,
		})
	}

	return (
		<Antd.Form<FieldType>
			className={`${styles.membership_details_form} ${props.className || ""}`}
			name="basic"
			labelCol={{ span: 8 }}
			wrapperCol={{ span: 16 }}
			initialValues={initialValues}
			onFinish={onFinish}
			autoComplete="off"
			layout="vertical"
		>
			<Antd.Form.Item<FieldType>
				className={styles.form_item}
				label={t("name")}
				name="name"
				wrapperCol={{ span: 24 }}
				validateTrigger="onSubmit"
				rules={[
					{
						required: true,
						message: t("please_enter_your_name"),
					},
				]}
			>
				<DvInput
					placeholder={t("insert_your_name")}
					onChange={(e) => {
						AppFunctions.setMembershipFormDataKey(
							"name",
							e.target.value
						)
					}}
				/>
			</Antd.Form.Item>

			<Antd.Form.Item<FieldType>
				className={styles.form_item}
				label={t("lastName")}
				name="lastName"
				wrapperCol={{ span: 24 }}
				validateTrigger="onSubmit"
				rules={[
					{
						required: true,
						message: t("please_enter_your_lastName"),
					},
				]}
			>
				<DvInput
					placeholder={t("insert_your_lastName")}
					onChange={(e) => {
						AppFunctions.setMembershipFormDataKey(
							"lastName",
							e.target.value
						)
					}}
				/>
			</Antd.Form.Item>

			<Antd.Form.Item<FieldType>
				className={styles.form_item}
				label={t("email")}
				name="email"
				wrapperCol={{ span: 24 }}
				validateTrigger="onSubmit"
				rules={[
					{
						required: true,
						message: t("please_enter_your_email"),
					},
					{
						type: "email",
						message: t("your_email_is_not_valid"),
					},
				]}
			>
				<DvInput
					placeholder={t("insert_your_email")}
					onChange={(e) => {
						AppFunctions.setMembershipFormDataKey(
							"email",
							e.target.value
						)
					}}
				/>
			</Antd.Form.Item>

			<Antd.Form.Item
				className={`${styles.form_item} ${styles.birthdate}`}
				label={t("birthdate")}
				name="birthdate"
				rules={[
					{
						required: true,
						message: t("please_enter_your_birthdate"),
					},
				]}
			>
				<Antd.DatePicker
					style={{ width: "100%" }}
					onChange={(e) => {
						AppFunctions.setMembershipFormDataKey(
							"birthdate",
							e?.toISOString()
						)
					}}
				/>
			</Antd.Form.Item>

			<DsButton
				style={{ display: "none" }}
				id={MemberShipLayoutConstants.SUBMIT_FORM_BUTTON_ID}
				className={styles.login_button}
				htmlType="submit"
			/>
		</Antd.Form>
	)
}

export default React.memo(MembershipDetailsForm)
