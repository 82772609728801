import React from "react"
import styles from "./AppLayout.module.scss"

import { Outlet, useLocation } from "react-router-dom"
import { AppRoutes } from "@constants/AppRoutes.ts"

import DsNavbar from "@components/layout/DsNavbar/DsNavbar.tsx"
import CardsBackground from "@components/layout/CardsBackground/CardsBackground.tsx"

interface IProps {
	className?: string
}

const AppLayout: React.FC<IProps> = (props) => {
	const location = useLocation()

	return (
		<div className={`${styles.app_layout} ${props.className || ""}`}>
			{location.pathname === AppRoutes.userSettings && (
				<CardsBackground />
			)}

			<DsNavbar className={styles.nav_bar} />
			<Outlet />
		</div>
	)
}

export default React.memo(AppLayout)
