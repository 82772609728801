import * as React from "react"

import { AppFunctions } from "@others/AppFunctions.ts"
import { IPostLoginResponseApi } from "@interfaces/IPostLoginResponseApi.ts"
import { AppIndexedDBKeys } from "@constants/AppIndexedDBKeys.ts"

import IndexedDB from "@services/IndexedDBService.ts"

interface ICtx {
	isLogged: boolean
	setIsLogged: React.Dispatch<React.SetStateAction<boolean>>
	loggedUser?: IPostLoginResponseApi["user"]
	setLoggedUser: React.Dispatch<
		React.SetStateAction<IPostLoginResponseApi["user"]>
	>
}

const InitialCtxState: ICtx = {
	isLogged: AppFunctions.isAuthenticated(),
	setIsLogged: () => {},
	setLoggedUser: () => {},
}

export const Context = React.createContext<ICtx>(InitialCtxState)

const CtxProvider: React.FC<{
	children: React.ReactNode
}> = ({ children }) => {
	const [isLogged, setIsLogged] = React.useState<ICtx["isLogged"]>(
		InitialCtxState.isLogged
	)

	const [loggedUser, setLoggedUser] = React.useState<ICtx["loggedUser"]>()

	React.useEffect(() => {
		if (!loggedUser) return
		IndexedDB.updateKey(AppIndexedDBKeys.LOGGED_USER, loggedUser) // updating the status of the user in indexedDB, creating a new key if it doesn't exist
	}, [loggedUser])

	React.useEffect(() => {
		// getting the initial statue from indexedDB
		IndexedDB.getKey<ICtx["loggedUser"]>(AppIndexedDBKeys.LOGGED_USER).then(
			(data) => {
				if (!data) return
				setLoggedUser(data)
			}
		)
	}, [])

	const contextValue = React.useMemo(
		() => ({
			isLogged,
			setIsLogged,
			loggedUser,
			setLoggedUser,
		}),
		[isLogged, setIsLogged, loggedUser, setLoggedUser]
	)

	return <Context.Provider value={contextValue}>{children}</Context.Provider>
}

export const AuthCtxProvider = CtxProvider
