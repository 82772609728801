import * as React from "react"
import styles from "./DvLanguageSelector.module.scss"

import { useTranslation } from "react-i18next"
import { AppLanguages } from "@constants/AppLanguages.ts"
import DvDropdown from "@components/navigation/DvDropdown/DvDropdown.tsx"

interface IProps {
	className?: string
}

const DvLanguageSelector: React.FC<IProps> = (props) => {
	const { i18n } = useTranslation()
	const [language, setLanguage] = React.useState<string>(i18n.language)

	const handleLanguageChange = (language: string) => {
		i18n.changeLanguage(language)
		setLanguage(language)
	}

	return (
		<DvDropdown
			className={`${styles.dv_language_selector} ${props.className || ""}`}
			items={[
				{
					key: "1",
					label: "EN",
					className: styles.item,
					onClick: () => handleLanguageChange(AppLanguages.ENGLISH),
				},
				{
					key: "2",
					label: "ES",
					className: styles.item,
					onClick: () => handleLanguageChange(AppLanguages.SPANISH),
				},
			]}
		>
			{language.toUpperCase()}
		</DvDropdown>
	)
}

export default React.memo(DvLanguageSelector)
