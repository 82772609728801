import * as React from "react"
import * as Antd from "antd"
import * as AntdIcons from "@ant-design/icons"
import styles from "./MembershipAddressForm.module.scss"

import { useTranslation } from "react-i18next"
import { AppRoutes } from "@constants/AppRoutes.ts"
import { useNavigate } from "react-router-dom"
import { AppFunctions } from "@others/AppFunctions.ts"
import { useListOfAllCountries } from "@hooks/useListOfAllCountries.tsx"
import { MemberShipLayoutConstants } from "@components/layout/MembershipLayout/MembershipLayout.constants.ts"

import DsButton from "@components/general/DsButton/DsButton.tsx"
import DvSelect from "@components/dataEntry/DvSelect/DvSelect.tsx"
import DvInput from "@components/dataEntry/DvInput/DvInput.tsx"

type FieldType = {
	country?: string
	city?: string
	address_1?: string
	address_2?: string
	zipCode?: string
}

interface IProps {
	className?: string
}

const MembershipAddressForm: React.FC<IProps> = (props) => {
	const navigate = useNavigate()
	const addressInputRef = React.useRef<Antd.InputRef>(null)
	const { t } = useTranslation()
	const [form] = Antd.Form.useForm<FieldType>()
	const [findAddress, setFindAddress] = React.useState<string>()
	const { countries, loading } = useListOfAllCountries()

	const [isFormFilledManually, setIsFormFilledManually] =
		React.useState(false)

	const [currentLocation, setCurrentLocation] =
		React.useState<google.maps.LatLng | null>(null) // Store user's location

	const initialValues: FieldType = {
		country: AppFunctions.getMembershipFormDataKey("country"),
		city: AppFunctions.getMembershipFormDataKey("city"),
		address_1: AppFunctions.getMembershipFormDataKey("address_1"),
		address_2: AppFunctions.getMembershipFormDataKey("address_2"),
		zipCode: AppFunctions.getMembershipFormDataKey("zipCode"),
	}

	const onFinishFailed = () => {
		setIsFormFilledManually(true)
	}

	const onFinish = ({
		country,
		address_1,
		address_2,
		city,
		zipCode,
	}: FieldType) => {
		AppFunctions.setMembershipFormDataKey("country", country)
		AppFunctions.setMembershipFormDataKey("address_1", address_1)
		AppFunctions.setMembershipFormDataKey("address_2", address_2)
		AppFunctions.setMembershipFormDataKey("city", city)
		AppFunctions.setMembershipFormDataKey("zipCode", zipCode)

		navigate(AppRoutes.membershipFormStep1_kind_of_membership, {
			replace: true,
		})
	}

	const extractAddressComponents = (
		addressComponents: google.maps.GeocoderAddressComponent[]
	) => {
		let country = ""
		let city = ""
		let zipCode = ""
		let address_2

		addressComponents.forEach((component) => {
			const types = component.types

			if (types.includes("country")) {
				country = component.short_name
			}

			if (types.includes("locality")) {
				city = component.long_name
			}

			if (types.includes("postal_code")) {
				zipCode = component.long_name
			}

			if (
				types.includes("sublocality") ||
				types.includes("neighborhood")
			) {
				address_2 = component.long_name
			}
		})

		return {
			country,
			city,
			zipCode,
			address_2,
		}
	}

	React.useEffect(() => {
		if (addressInputRef.current && window.google) {
			const autocompleteInstance =
				new window.google.maps.places.Autocomplete(
					addressInputRef.current.input!,
					{
						types: ["geocode"],
					}
				)

			if (currentLocation) {
				autocompleteInstance.setBounds(
					new google.maps.LatLngBounds(currentLocation)
				)
			}

			autocompleteInstance.addListener("place_changed", () => {
				const place = autocompleteInstance.getPlace()
				const addressComponents = place.address_components
				const address_1 = place.formatted_address

				setFindAddress(address_1)

				if (addressComponents) {
					const extractedAddress =
						extractAddressComponents(addressComponents)

					form.setFieldsValue({
						address_1,
						...extractedAddress,
					})

					AppFunctions.setMembershipFormDataKey(
						"address_1",
						address_1
					)
					AppFunctions.setMembershipFormDataKey(
						"address_2",
						extractedAddress.address_2
					)
					AppFunctions.setMembershipFormDataKey(
						"city",
						extractedAddress.city
					)
					AppFunctions.setMembershipFormDataKey(
						"zipCode",
						extractedAddress.zipCode
					)
					AppFunctions.setMembershipFormDataKey(
						"country",
						extractedAddress.country
					)
				}
			})
		}
	}, [currentLocation, form])

	React.useEffect(() => {
		AppFunctions.loadGoogleMapsAPI(() => {
			AppFunctions.getUserLocation(({ lng, lat }) => {
				setCurrentLocation(new google.maps.LatLng(lat, lng))
			})
		})
	}, [])

	return (
		<Antd.Form<FieldType>
			className={`${styles.membership_address_form} ${props.className || ""}`}
			form={form}
			name="basic"
			labelCol={{ span: 8 }}
			wrapperCol={{ span: 16 }}
			initialValues={initialValues}
			onFinishFailed={onFinishFailed}
			onFinish={onFinish}
			autoComplete="off"
			layout="vertical"
		>
			<Antd.Form.Item<FieldType>
				className={styles.form_item}
				label={t("find_address")}
				wrapperCol={{ span: 24 }}
			>
				<DvInput
					ref={addressInputRef}
					placeholder={t("insert_an_address")}
					suffix={<AntdIcons.SearchOutlined />}
					value={findAddress}
					onChange={(e) => setFindAddress(e.target.value)}
					disabled={isFormFilledManually}
				/>
			</Antd.Form.Item>

			<DsButton
				className={styles.manual_address_button}
				type="text"
				onClick={() => setIsFormFilledManually(!isFormFilledManually)}
				icon={
					!isFormFilledManually ? (
						<AntdIcons.EditOutlined />
					) : (
						<AntdIcons.SearchOutlined />
					)
				}
			>
				{!isFormFilledManually
					? t("insert_address_manually")
					: t("use_address_finder")}
			</DsButton>

			<Antd.Form.Item<FieldType>
				className={styles.form_item}
				label={t("country-region")}
				name="country"
				wrapperCol={{ span: 24 }}
				validateTrigger="onSubmit"
				rules={[
					{
						required: true,
						message: t("please_enter_your_country_region"),
					},
				]}
			>
				<DvSelect
					options={countries}
					disabled={!isFormFilledManually}
					loading={loading}
					onSelect={(value) => {
						AppFunctions.setMembershipFormDataKey("country", value)
					}}
				/>
			</Antd.Form.Item>

			<Antd.Form.Item<FieldType>
				className={styles.form_item}
				label={t("address_line_#").replace("#", "1")}
				name="address_1"
				wrapperCol={{ span: 24 }}
				validateTrigger="onSubmit"
				rules={[
					{
						required: true,
						message: t("please_enter_your_address"),
					},
				]}
			>
				<DvInput
					placeholder={t("insert_your_address")}
					disabled={!isFormFilledManually}
					onChange={(e) => {
						AppFunctions.setMembershipFormDataKey(
							"address_1",
							e.target.value
						)
					}}
				/>
			</Antd.Form.Item>

			<Antd.Form.Item<FieldType>
				className={styles.form_item}
				label={t("address_line_#").replace("#", "2")}
				name="address_2"
				wrapperCol={{ span: 24 }}
			>
				<DvInput
					placeholder={t("insert_your_address")}
					disabled={!isFormFilledManually}
					onChange={(e) => {
						AppFunctions.setMembershipFormDataKey(
							"address_2",
							e.target.value
						)
					}}
				/>
			</Antd.Form.Item>

			<Antd.Form.Item<FieldType>
				className={styles.form_item}
				label={t("city")}
				name="city"
				wrapperCol={{ span: 24 }}
				validateTrigger="onSubmit"
				rules={[
					{
						required: true,
						message: t("please_enter_your_city"),
					},
				]}
			>
				<DvInput
					placeholder={t("insert_your_city")}
					disabled={!isFormFilledManually}
				/>
			</Antd.Form.Item>

			<Antd.Form.Item<FieldType>
				className={styles.form_item}
				label={t("postal_zip_code")}
				name="zipCode"
				wrapperCol={{ span: 24 }}
				validateTrigger="onSubmit"
				rules={[
					{
						required: true,
						message: t("please_enter_your_postal_zip_code"),
					},
				]}
			>
				<DvInput
					placeholder={t("insert_your_postal_zip_code")}
					disabled={!isFormFilledManually}
				/>
			</Antd.Form.Item>

			<DsButton
				style={{ display: "none" }}
				id={MemberShipLayoutConstants.SUBMIT_FORM_BUTTON_ID}
				className={styles.login_button}
				htmlType="submit"
			>
				{t("start_session")}
			</DsButton>
		</Antd.Form>
	)
}

export default React.memo(MembershipAddressForm)
