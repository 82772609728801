import * as React from "react"
import * as Antd from "antd"
import styles from "./DvSelect.module.scss"

import { useTranslation } from "react-i18next"

interface IProps extends Antd.SelectProps {
	className?: string
	placeholder?: string
}

const DvSelect: React.FC<IProps> = ({ className, placeholder, ...props }) => {
	const { t } = useTranslation()

	return (
		<Antd.Select
			{...props}
			className={`${styles.dv_select} ${className || ""}`}
			placeholder={placeholder || t("select_an_option")}
			getPopupContainer={() =>
				document.getElementsByClassName(
					styles.dv_select
				)[0] as HTMLElement
			}
		/>
	)
}

export default React.memo(DvSelect)
