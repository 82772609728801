import * as React from "react"
import styles from "./MembershipFormStep5.module.scss"

import { useTranslation } from "react-i18next"
import { Outlet } from "react-router-dom"
import { MemberShipLayoutConstants } from "@components/layout/MembershipLayout/MembershipLayout.constants.ts"

import DsSteps from "@components/feedback/DsSteps/DsSteps.tsx"

interface IProps {
	className?: string
}

const MembershipFormStep5: React.FC<IProps> = (props) => {
	const { t } = useTranslation()

	return (
		<div
			className={`${styles.membership_form_step_5} ${props.className || ""}`}
		>
			<div className={styles.header}>
				<DsSteps
					currentStep={5}
					totalSteps={MemberShipLayoutConstants.totalSteps - 1}
					percentage={
						(5 / MemberShipLayoutConstants.totalSteps) * 100
					}
				/>

				<h1 className={styles.title}>{t("send_membership")}</h1>
			</div>

			<Outlet />
		</div>
	)
}

export default React.memo(MembershipFormStep5)
