import * as React from "react"
import * as Antd from "antd"

import { NotificationPlacement } from "antd/es/notification/interface"

const AntdNotification = Antd.notification

AntdNotification.config({})

const infoDuration = 5
const warningDuration = 8
const errorDuration = 8

const defaultPlacement = "bottomLeft"

interface IMessageArgs {
	message: string | React.ReactNode
	placement?: NotificationPlacement
	duration?: number | null
	onClose?: () => void
}

export const AppNotification = {
	success: async ({
		message,
		duration,
		placement,
		onClose,
	}: IMessageArgs) => {
		return AntdNotification.success({
			message,
			placement: placement || defaultPlacement,
			duration: duration || infoDuration,
			onClose,
		})
	},

	warning: async ({
		message,
		duration,
		placement,
		onClose,
	}: IMessageArgs) => {
		return AntdNotification.warning({
			message,
			placement: placement || defaultPlacement,
			duration: duration || warningDuration,
			onClose,
		})
	},

	error: async ({ message, duration, placement, onClose }: IMessageArgs) => {
		return AntdNotification.error({
			message,
			placement: placement || defaultPlacement,
			duration: duration || errorDuration,
			onClose,
		})
	},
}
